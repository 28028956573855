import { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

export default function TextTypewriterAnimation(): JSX.Element {
    useEffect(() => {
        const target = ".we-are-text-animation > span";
        const elements = Array.from(document.querySelectorAll(target));

        const trigger = ScrollTrigger.create({
            trigger: ".we-are-text-animation",
            start: "top 80%",
            end: "bottom 40%",
            scrub: true,
            onUpdate: self => {
                const index = Math.floor(self.progress * elements.length);
                elements.forEach((element, i) => {
                    if (i <= index) {
                        gsap.to(element, {
                            color: "var(--color-green)",
                            duration: 0
                        });
                    } else {
                        gsap.to(element, {
                            color: "var(--color-grey)",
                            duration: 0
                        });
                    }
                });
            }
        });

        return () => {
            trigger.kill();
        };
    }, []);

    return null;
}
